import React from "react";

const Profile = () => {
  const user = {
    name: "John Doe",
    email: "johndoe@example.com",
    bio: "Web developer with a passion for creating user-friendly applications.",
    profilePic: "img/profile-pic.png", // Link to the user's profile picture
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        {/* Profile Picture */}
        <img src={user.profilePic} alt="Profile" className="profile-pic" />
        {/* User Info */}
        <h2>{user.name}</h2>
        <p className="email">{user.email}</p>
        <p className="bio">{user.bio}</p>

        {/* Edit Profile Button (you can later add functionality to allow editing) */}
        <button className="edit-btn">Edit Profile</button>
      </div>
    </div>
  );
};

export default Profile;
