import './Dashboard.css';
import React, { useEffect, useState } from 'react';

const Dashboard = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productToAdd, setProductToAdd] = useState({
        SizeLabel: '',
        Price: ''
    });
    const sellerName = localStorage.getItem('username') || 'defaultSeller';

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await fetch(`http://sell.onesole.eu/back/stock.php?seller=${sellerName}`);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setItems(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [sellerName]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter items based on search query for suggestions
    const filteredItems = items.filter(item => 
        item.ProductName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const openAddProductModal = (product) => {
        setSelectedProduct(product);
        setProductToAdd({
            SizeLabel: '',
            Price: ''
        });
        setShowAddProductModal(true);
    };

    const closeAddProductModal = () => {
        setShowAddProductModal(false);
    };

    const handleAddProductInputChange = (event) => {
        const { name, value } = event.target;
        setProductToAdd((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleAddProductSubmit = async (event) => {
        event.preventDefault();

        try {
            const addProductData = {
                ProductID: selectedProduct.ProductID,
                SizeLabel: productToAdd.SizeLabel,
                Price: productToAdd.Price,
                sellerName
            };

            const response = await fetch('http://sell.onesole.eu/back/add_product.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(addProductData)
            });

            if (!response.ok) {
                throw new Error('Failed to add product');
            }

            const addedProduct = await response.json();
            setItems([...items, addedProduct]);
            closeAddProductModal();
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <section id="dashboard">
            <h1>Dashboard</h1>

            {/* Search bar */}
            <input 
                type="text" 
                placeholder="Search by product name" 
                value={searchQuery} 
                onChange={handleSearch} 
                style={{ marginBottom: '20px', padding: '10px', width: '100%' }}
            />

            {/* Suggested Products */}
            {searchQuery && filteredItems.length > 0 && (
                <div className="suggestion-list">
                    {filteredItems.map((item) => (
                        <div key={item.ProductID} style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{item.ProductName}</span>
                            <button onClick={() => openAddProductModal(item)} style={{ marginLeft: '10px' }}>+</button>
                        </div>
                    ))}
                </div>
            )}

            {/* Add Product Modal */}
            {showAddProductModal && selectedProduct && (
                <div className="modal">
                    <div className="modal-content">
                        <button onClick={closeAddProductModal} style={{ float: 'right' }}>Close</button>
                        <h2>Add Product: {selectedProduct.ProductName}</h2>

                        <form onSubmit={handleAddProductSubmit}>
                            <label>
                                Size:
                                <input 
                                    type="text" 
                                    name="SizeLabel" 
                                    value={productToAdd.SizeLabel} 
                                    onChange={handleAddProductInputChange} 
                                    required 
                                />
                            </label>
                            <label>
                                Price:
                                <input 
                                    type="text" 
                                    name="Price" 
                                    value={productToAdd.Price} 
                                    onChange={handleAddProductInputChange} 
                                    required 
                                />
                            </label>
                            <button type="submit">Save and Add to Stock</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Display existing items */}
            <ul id="ulDash">
                {items.length > 0 ? (
                    items.map((item) => (
                        <li key={item.ProductID} id="liDash">
                            <img src={item.ImagePath} alt={item.ProductName} style={{ width: '100px' }} />
                            <h3>{item.ProductName}</h3>
                            <p>Price: {item.Price}</p>
                            <p>Size: {item.SizeLabel}</p>
                        </li>
                    ))
                ) : (
                    <p>No items found.</p>
                )}
            </ul>
        </section>
    );
};

export default Dashboard;
